.inner {
  background-color: var(--red);
  position: relative;
  height: 90svh;
  width: min(44.52svh, 100%);
  opacity: 0;
  transition: opacity 0.5s;
}

.innerShow {
  opacity: 1;
}

.video {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.cta {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 13.5svh;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.grabber {
  position: absolute;
  top: calc(331 / 1500 * 100%);
  left: 50%;
  margin-left: calc(-44.52svh / 2);
  width: 44.52svh;
  height: calc(937 / 1500 * 100%);
}

@keyframes shrink {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

.shrink {
  animation: shrink 0.5s;
  animation-fill-mode: both;
}
