.button {
  background: transparent;
  border: 0.5svh solid var(--brown);
  border-radius: 50%;
  width: 10svh;
  height: 10svh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.triangleIcon {
  width: 0;
  height: 0;
  border-left: 3svh solid var(--brown);
  border-top: 2svh solid transparent;
  border-bottom: 2svh solid transparent;
  margin-left: 1svh;
}

.pauseIcon {
  width: 3svh;
  height: 3.5svh;
  position: relative;
}

.pauseIcon::before,
.pauseIcon::after {
  content: "";
  position: absolute;
  background: var(--brown);
  width: 33%;
  height: 100%;
}

.pauseIcon::before {
  left: 0;
}

.pauseIcon::after {
  right: 0;
}
