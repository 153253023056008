.root {
  background-color: var(--brown);
  color: var(--yellow);
  border-radius: 3vw;
  padding: 2.5vw 10vw;
  font-size: 8vw;
  cursor: pointer;
  font-family: RiffleSansHeadline;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
}

.root.svh {
  --svh: 0.4;
  border-radius: calc(var(--svh) * 3svh);
  padding: calc(var(--svh) * 2.5svh) calc(var(--svh) * 10svh);
  font-size: calc(var(--svh) * 8svh);
}

:global(.lang-de) .root.svh {
  padding: calc(var(--svh) * 2.5svh) calc(var(--svh) * 4svh);
}

@media (min-width: 300px) {
  .root:not(.svh) {
    border-radius: calc(4 * var(--scale));
    padding: calc(2.5 * var(--scale)) calc(6 * var(--scale));
    font-size: calc(10 * var(--scale));
  }
}
