.text {
  text-transform: uppercase;
  font-family: RiffleSansBold;
  font-size: 3svh;
  color: var(--brown);
  margin-top: 2svh;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
}

.loadingErrorWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingErrorMessage {
  background-color: white;
  padding: 5svh 2svh;
  font-size: 3svh;
  border-radius: 1svh;
  width: 80%;
  text-align: center;
}
