.root {
  position: relative;
}

.content {
  position: absolute;
  font-family: RiffleSansHeadline;
  font-size: 3svh;
  text-transform: uppercase;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.lang-de) .content {
  font-size: 2svh;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  border: 0.5svh solid var(--red);
  z-index: 1;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: spin 1s linear infinite;
}
