.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  gap: 3vw;
  color: var(--brown);
  padding: 10vw 0;
}

.heading {
  font-size: 15vw;
  text-align: center;
  font-family: RiffleSansHeadline;
  text-transform: uppercase;
  line-height: 0.9;
}

.subheading {
  font-size: 8vw;
  text-align: center;
}

@media (min-width: 300px) {
  .heading {
    font-size: calc(15 * var(--scale));
  }
  .subheading {
    font-size: calc(8 * var(--scale));
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10vw;
  width: 100%;
}

.formSection {
  width: 100%;
  display: grid;
  gap: 5vw;
}

.label {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  font-size: 5vw;
}

.formHeading {
  font-size: 5vw;
}

.input {
  font-size: 5vw;
  padding: 6vw;
  background-color: var(--brown);
  color: var(--yellow);
  border-radius: 4vw;
  border: 3px solid transparent;
}

.input.error {
  border: 3px solid var(--yellow);
}

@media (min-width: 300px) {
  .root {
    width: calc(90 * var(--scale));
    gap: calc(5 * var(--scale));
    padding: calc(10 * var(--scale)) 0;
  }
  .form {
    gap: calc(10 * var(--scale));
  }
  .formSection {
    gap: calc(3 * var(--scale));
  }
  .label {
    gap: calc(1 * var(--scale));
    font-size: calc(5.5 * var(--scale));
  }
  .formHeading {
    font-size: calc(5.5 * var(--scale));
  }
  .input {
    font-size: calc(5.5 * var(--scale));
    padding: calc(6 * var(--scale));
    border-radius: calc(4 * var(--scale));
  }
}

.checkbox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;

  input {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }
}
