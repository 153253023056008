.root {
  display: flex;
  flex-direction: column;
  gap: 5vw;
  width: 90%;
}

@media (min-width: 300px) {
  .root {
    gap: calc(5 * var(--scale));
    width: calc(90 * var(--scale));
  }
}
