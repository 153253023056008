.root {
  position: relative;
}

.outer {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.inner {
  height: 90%;
  left: 5%;
  position: absolute;
  top: 5%;
  width: 90%;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 5s linear infinite;
}
