.root {
  font-family: sans-serif;
  text-transform: none;
  font-size: 1rem;
  line-height: 1.4;
  padding: 4rem 1rem;
  max-width: 800px;
  margin: 0 auto;

  * {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  li {
    margin-left: 1rem;
  }
}
