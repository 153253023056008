.root {
  background-color: var(--yellow);
  border-radius: 8vw;
  padding: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  gap: 5vw;
}

@media (min-width: 300px) {
  .root {
    padding: calc(8 * var(--scale));
    border-radius: calc(8 * var(--scale));
    width: calc(90 * var(--scale));
    gap: calc(5 * var(--scale));
  }
}
