.heading {
  font-size: 10vw;
  text-align: center;
  font-family: RiffleSansHeadline;
  text-transform: uppercase;
  line-height: 0.9;
}

:global(.lang-de) .heading {
  font-size: 9vw;
}

.subheading {
  font-size: 7vw;
  text-align: center;
}

.body {
  font-size: 4.5vw;
  text-align: center;
}

@media (min-width: 300px) {
  .heading {
    font-size: calc(10 * var(--scale));
  }
  .subheading {
    font-size: calc(7 * var(--scale));
  }
  .body {
    font-size: calc(4.5 * var(--scale));
  }
}

@media (min-width: 300px) {
  :global(.lang-de) .heading {
    font-size: calc(9 * var(--scale));
  }
}
