.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100svh;
}

.fitHeight {
  height: 100svh;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.show {
  animation: fadeIn 0.5s;
  animation-fill-mode: both;
}

.hide {
  animation: fadeOut 0.5s;
  animation-fill-mode: both;
}
