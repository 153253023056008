@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s;
  animation-fill-mode: both;
  z-index: 1;
}

.inner {
  position: relative;
}

.content {
  position: absolute;
  top: 7%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1svh;
}

.heading {
  font-size: 6.75svh;
  text-align: center;
  font-family: RiffleSansHeadline;
  text-transform: uppercase;
  line-height: 0.9;
}

:global(.lang-de) .heading {
  font-size: 4svh;
}

.subheading {
  font-size: 3.2svh;
  text-align: center;
  line-height: 1;
  width: 75%;
  margin-bottom: 1svh;
}

:global(.lang-de) .subheading {
  width: 90%;
}
