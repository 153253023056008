@font-face {
  font-family: RiffleSansRegular;
  src: url(fonts/RiffleSans-Regular.woff) format("woff");
}
@font-face {
  font-family: RiffleSansBold;
  src: url(fonts/RiffleSans-Bold.woff) format("woff");
}
@font-face {
  font-family: RiffleSansHeadline;
  src: url(fonts/RiffleSans-Headline.woff) format("woff");
}

:root {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--red);
  color: var(--brown);
  font-family: RiffleSansRegular;
  line-height: 1.25;
  transition: background-color 0.5s;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

img {
  display: block;
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

input:focus-visible,
:focus-visible {
  outline: 3px solid white;
}

a {
  color: inherit;
  font-weight: bold;
}
